// ToDo: Map form validation schema to config.
const template = [
  {
    component: 'Box',
    props: {
      sx: {
        background: 'linear-gradient(135deg, #2d2169, #2d2169)',
        margin: 0,
        padding: 0,
      },
    },
    children: [
      {
        component: 'AppBar',
        props: {
          position: 'static',
          sx: {
            background: 'linear-gradient(135deg, #2d2169, #2d2169)',
            height: '64px',
          },
        },
        children: [
          {
            component: 'Toolbar',
            props: {
              sx: {
                justifyContent: 'start',
                gap: 1,
              },
            },
            children: [
              {
                component: 'IconButton',
                props: {
                  sx: {
                    color: 'white',
                    href: '/',
                  },
                },
                children: [
                  {
                    component: 'Avatar',
                    props: {
                      src: '/assets/winston.png',
                      alt: 'Logo',
                      width: '96px',
                      height: '96px',
                    },
                  },
                ],
              },
              {
                component: 'Typography',
                props: {
                  variant: 'h6',
                  color: 'white',
                },
                content: 'Dashboard',
              },
            ],
          },
        ],
      },

      {
        component: 'Box',
        props: {
          sx: {
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            flex: 1,
          },
        },
        children: [
          {
            component: 'Box',
            props: {
              sx: {
                display: { xs: 'none', md: 'block' },
                position: 'relative',
                top: 0,
                left: 0,
                minWidth: '200px',
                maxWidth: '240px',
                height: 'calc(100vh - 64px)',
                borderRight: '1px solid #efefef',
                padding: '16px',
                gap: 2,
              },
            },
            children: [
              {
                component: 'Typography',
                props: {
                  variant: 'h6',
                  color: 'white',
                },
                content: 'Winston',
              },
              {
                component: 'Divider',
                props: {
                  sx: {
                    borderColor: 'white',
                  },
                },
              },

              {
                component: 'Box',
                props: {
                  sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  },
                },
                children: [
                  {
                    component: 'List',
                    props: {
                      variant: 'h6',
                      color: 'white',
                    },
                    children: [
                      {
                        component: 'MenuItem',
                        props: {
                          sx: {
                            color: 'white',
                          },
                        },
                        action: {
                          method: 'useNavigate',
                          args: ['/dashboard'],
                        },
                        content: 'Dashboard',
                      },
                      {
                        component: 'MenuItem',
                        props: {
                          sx: {
                            color: 'white',
                          },
                        },
                        action: {
                          method: 'useAuth',
                          type: 'logout',
                          args: [],
                        },
                        content: 'Logout',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            component: 'Container',
            props: {
              maxWidth: 'xl',
            },
            children: [
              {
                component: 'Box',
                props: {
                  sx: {
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                    backgroundImage: 'url(/assets/image.png)',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: 'calc(100vh - 64px)',
                    width: '100%',
                    flex: 1,
                    flexDirection: 'column',
                    paddingY: '16px',
                    gap: 1,
                  },
                },
                children: [
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 1,
                        width: '100%',
                      },
                    },
                    children: [
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            width: { xs: '100%', md: '30%' },
                          },
                        },
                        children: [
                          {
                            component: 'Typography',
                            props: {
                              variant: 'h4',
                              color: 'white',
                              fontFamily: 'Cookie',
                            },
                            content: 'Welcome to Winston',
                          },

                          {
                            component: 'Typography',
                            props: {
                              variant: 'caption',
                              color: 'white',
                              fontFamily: 'Cookie',
                            },
                            content:
                              'Your personal assistant to all things blockchain.',
                          },
                        ],
                      },
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 1,
                            width: '100%',
                          },
                        },
                        children: [
                          {
                            component: 'Paper',
                            props: {
                              elevation: 3,
                              sx: {
                                padding: '6px',
                                background:
                                  'linear-gradient(135deg, #2d2169, #2d2169)',
                                color: 'white',
                                width: { xs: '100%', md: '30%' },
                              },
                            },
                            children: [
                              {
                                component: 'Box',
                                props: {
                                  sx: {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    gap: 1,
                                  },
                                },
                                children: [
                                  {
                                    component: 'Avatar',
                                    props: {
                                      src: '/assets/ahwa.png',
                                      alt: 'Logo',
                                      width: '32px',
                                      height: '32px',
                                    },
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h6',
                                    },
                                    content: 'Ahwa (AHWA)',
                                  },
                                ],
                              },
                              {
                                component: 'Box',
                                props: {
                                  sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                  },
                                },
                                children: [
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'caption',
                                    },
                                    content:
                                      'Total Supply : 10000 Decimals: 18',
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            component: 'Paper',
                            props: {
                              elevation: 3,
                              sx: {
                                padding: '6px',
                                background:
                                  'linear-gradient(135deg, #2d2169, #2d2169)',
                                color: 'white',
                                width: { xs: '100%', md: '30%' },
                              },
                            },
                            children: [
                              {
                                component: 'Box',
                                props: {
                                  sx: {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    gap: 1,
                                  },
                                },
                                children: [
                                  {
                                    component: 'Avatar',
                                    props: {
                                      src: '/assets/winston.png',
                                      alt: 'Logo',
                                      width: '32px',
                                      height: '32px',
                                    },
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h6',
                                    },
                                    content: 'Winston (WIN)',
                                  },
                                ],
                              },
                              {
                                component: 'Box',
                                props: {
                                  sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                  },
                                },
                                children: [
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'caption',
                                    },
                                    content:
                                      'Total Supply : 100000000 Decimals: 18',
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            component: 'Paper',
                            props: {
                              elevation: 3,
                              sx: {
                                padding: '6px',
                                background:
                                  'linear-gradient(135deg, #2d2169, #2d2169)',
                                color: 'white',
                                width: { xs: '100%', md: '30%' },
                              },
                            },
                            children: [
                              {
                                component: 'Box',
                                props: {
                                  sx: {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    gap: 1,
                                  },
                                },
                                children: [
                                  {
                                    component: 'Avatar',
                                    props: {
                                      src: '/assets/rickle.png',
                                      alt: 'Logo',
                                      width: '32px',
                                      height: '32px',
                                    },
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h6',
                                    },
                                    content: 'Rickle (rkl)',
                                  },
                                ],
                              },
                              {
                                component: 'Box',
                                props: {
                                  sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                  },
                                },
                                children: [
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'caption',
                                    },
                                    content:
                                      'Total Supply : 1000000000 Decimals: 18',
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            component: 'Paper',
                            props: {
                              elevation: 3,
                              sx: {
                                padding: '6px',
                                background:
                                  'linear-gradient(135deg, #2d2169, #2d2169)',
                                color: 'white',
                                width: { xs: '100%', md: '30%' },
                              },
                            },
                            children: [
                              {
                                component: 'Box',
                                props: {
                                  sx: {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    gap: 1,
                                  },
                                },
                                children: [
                                  {
                                    component: 'Avatar',
                                    props: {
                                      src: '/assets/wac.png',
                                      alt: 'Logo',
                                      width: '32px',
                                      height: '32px',
                                    },
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h6',
                                    },
                                    content: 'Academy (WAC)',
                                  },
                                ],
                              },
                              {
                                component: 'Box',
                                props: {
                                  sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                  },
                                },
                                children: [
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'caption',
                                    },
                                    content:
                                      'Total Supply : 100000000000 Decimals: 18',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
                content: null,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default template;
