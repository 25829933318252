import { createSlice } from '@reduxjs/toolkit';
import template from '../pages/home/templates/template.03';
import dashboardTemplate from '../pages/home/templates/dashboard-template.01';
import * as yup from 'yup';

const initialState = {
  drawer: {
    anchor: 'right',
    top: { content: null, open: false },
    left: { content: null, open: false },
    bottom: { content: null, open: false },
    right: { content: null, open: false },
  },
  errors: [
    {
      id: 1,
      message: 'test',
      type: 'warning',
      hasRead: false,
      hasShown: false,
    },
  ],
  modal: {
    component: () => null,
    open: false,
    props: {},
  },
  notifications: [
    {
      id: 1,
      message: 'test',
      type: 'success',
      hasRead: false,
      hasShown: false,
    },
  ],
  routes: [
    {
      name: 'Home',
      path: '/',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/',
          element: { component: 'Home', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'home',
      config: {
        activeSections: template,
      },
    },
    {
      name: 'Wallet',
      path: '/wallet',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/wallet',
          element: { component: 'Wallet', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'wallet',
      config: {
        activeSections: [],
      },
    },
    {
      name: 'Sign In',
      path: '/sign-in',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/sign-in',
          element: { component: 'SignIn', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'sign-in',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            },
            children: [
              {
                component: 'Form',
                props: {
                  initialValues: {
                    email: '',
                    password: '',
                  },
                  validationSchema: yup.object({
                    email: yup
                      .string()
                      .email('Invalid email')
                      .required('Email is required'),
                    password: yup.string().required('Password is required'),
                  }),
                },
                action: {
                  method: 'useForm',
                  type: 'fetch',
                },
                onSubmit: {
                  type: 'post',
                  url: 'https://winston.services/api/v1/auth/login',
                  onSuccess: {
                    useAuth: true,
                    type: 'redirect',
                    url: '/dashboard',
                    message: 'Login successful',
                    variant: 'success',
                  },
                  onError: {
                    type: 'notification',
                    message: 'Invalid email or password',
                    variant: 'error',
                  },
                },
                children: [
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h2',
                      sx: { fontFamily: 'Cookie' },
                    },
                    content: 'Welcome back!',
                  },
                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: 'column',
                      marginY: 1,
                    },
                    children: [
                      {
                        component: 'EmailTextField',
                        props: {},
                        content: null,
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: 'column',
                      marginY: 1,
                    },
                    children: [
                      {
                        component: 'PasswordTextField',
                        props: {},
                        content: null,
                      },
                    ],
                  },

                  {
                    component: 'Button',
                    props: {
                      variant: 'contained',
                      type: 'submit',
                      fullWidth: true,
                    },
                    content: 'Submit',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Register',
      path: '/register',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/register',
          element: { component: 'Register', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'register',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            },
            children: [
              {
                component: 'Form',
                props: {
                  initialValues: {
                    email: '',
                    password: '',
                    'confirm-password': '',
                  },
                  validationSchema: yup.object({
                    email: yup
                      .string()
                      .email('Invalid email')
                      .required('Email is required'),
                    password: yup.string().required('Password is required'),
                    'confirm-password': yup
                      .string()
                      .oneOf(
                        [yup.ref('password'), null],
                        'Passwords must match'
                      )
                      .required('Confirm password is required'),
                  }),
                },
                action: {
                  method: 'useForm',
                  type: 'fetch',
                },
                onSubmit: {
                  type: 'post',
                  url: 'https://winston.services/api/v1/user/register',
                  onSuccess: {
                    useAuth: false,
                    type: 'redirect',
                    variant: 'success',
                    message: 'User created successfully',
                    url: '/sign-in',
                  },
                  onError: {
                    type: 'notification',
                    message: 'Invalid email or password',
                    variant: 'error',
                  },
                },
                children: [
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h2',
                      sx: { fontFamily: 'Cookie' },
                    },
                    content: 'Welcome! Please register to continue.',
                  },
                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: 'column',
                      marginY: 1,
                    },
                    children: [
                      {
                        component: 'EmailTextField',
                        props: {},
                        content: null,
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: 'column',
                      marginY: 1,
                    },
                    children: [
                      {
                        component: 'PasswordTextField',
                        props: {},
                        content: null,
                      },
                    ],
                  },

                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: 'column',
                      marginY: 1,
                    },
                    children: [
                      {
                        component: 'ConfirmPasswordTextField',
                        props: {},
                        content: null,
                      },
                    ],
                  },
                  {
                    component: 'Button',
                    props: {
                      variant: 'contained',
                      type: 'submit',
                      fullWidth: true,
                    },
                    content: 'Submit',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Page',
      path: '/public-notice',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/public-notice',
          element: { component: 'Page', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'page',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {
              sx: {
                background: 'linear-gradient(135deg, #2d2169, #2d2169)',
                margin: 0,
                padding: 0,
              },
            },
            children: [
              {
                component: 'Container',
                props: {
                  maxWidth: 'xl',
                },
                children: [
                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'start',
                      height: '100vh',
                      color: 'white',
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h2',
                          sx: {
                            fontFamily: 'Cookie',
                            alignSelf: 'center',
                          },
                        },
                        content: 'Public Notice',
                      },
                      {
                        component: 'Box',
                        props: {
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignContent: 'center',
                          height: '100%',
                          width: '100%',
                          overflow: 'hidden',
                        },
                        children: [
                          {
                            component: 'Box',
                            props: {
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-evenly',
                              alignItems: 'center',
                              gap: 2,
                              sx: {
                                height: '100%',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                  width: '8px',
                                },
                                '&::-webkit-scrollbar-track': {
                                  background: '#2d2169',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  background:
                                    'linear-gradient(135deg, #2d2169, #2d2169)',
                                  borderRadius: '4px',
                                },
                              },
                            },
                            children: [
                              {
                                component: 'Box',
                                props: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginY: 2,
                                  sx: {
                                    width: { xs: '100%', md: '60%' }, // Limiting width to 80% of the parent container
                                    mx: 'auto', // Centering the box
                                  },
                                },
                                children: [
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h4',
                                    },
                                    children: [
                                      {
                                        component: 'Content',
                                        props: {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        },
                                        content:
                                          'Public Notice : 09/11/2021 - 09/11/2021 ',
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Approved',
                                          variant: 'outlined',
                                          color: 'success',
                                        },
                                      },

                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Failed',
                                          variant: 'outlined',
                                          color: 'error',
                                        },
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'On-Going',
                                          variant: 'outlined',
                                          color: 'warning',
                                        },
                                      },
                                      {
                                        component: 'Button',
                                        props: {
                                          variant: 'contained',
                                          color: 'info',
                                          sx: {
                                            borderRadius: '26px',
                                            textTransform: 'none',
                                          },
                                        },
                                        content: 'Vote',
                                      },
                                    ],
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h6',
                                    },
                                    content:
                                      'Proposal Id : 1234567890 by 0x0000...0000',
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'body1',
                                      sx: {
                                        marginX: 1,
                                      },
                                    },
                                    content:
                                      'I propose to change the name of the company to "The New Company" and to change the logo to a new one.',
                                  },
                                ],
                              },
                              {
                                component: 'Box',
                                props: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginY: 2,
                                  sx: {
                                    width: { xs: '100%', md: '60%' }, // Limiting width to 80% of the parent container
                                    mx: 'auto', // Centering the box
                                  },
                                },
                                children: [
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h4',
                                    },
                                    children: [
                                      {
                                        component: 'Content',
                                        props: {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        },
                                        content:
                                          'Public Notice : 09/11/2021 - 09/11/2021 ',
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Approved',
                                          variant: 'outlined',
                                          color: 'success',
                                        },
                                      },

                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Failed',
                                          variant: 'outlined',
                                          color: 'error',
                                        },
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'On-Going',
                                          variant: 'outlined',
                                          color: 'warning',
                                        },
                                      },
                                      {
                                        component: 'Button',
                                        props: {
                                          variant: 'contained',
                                          color: 'info',
                                          sx: {
                                            borderRadius: '26px',
                                            textTransform: 'none',
                                          },
                                        },
                                        content: 'Vote',
                                      },
                                    ],
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h6',
                                    },
                                    content:
                                      'Proposal Id : 1234567890 by 0x0000...0000',
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'body1',
                                      sx: {
                                        marginX: 1,
                                      },
                                    },
                                    content:
                                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
                                      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                                  },
                                ],
                              },
                              {
                                component: 'Box',
                                props: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginY: 2,
                                  sx: {
                                    width: { xs: '100%', md: '60%' }, // Limiting width to 80% of the parent container
                                    mx: 'auto', // Centering the box
                                  },
                                },
                                children: [
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h4',
                                    },
                                    children: [
                                      {
                                        component: 'Content',
                                        props: {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        },
                                        content:
                                          'Public Notice : 09/11/2021 - 09/11/2021 ',
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Approved',
                                          variant: 'outlined',
                                          color: 'success',
                                        },
                                      },

                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Failed',
                                          variant: 'outlined',
                                          color: 'error',
                                        },
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'On-Going',
                                          variant: 'outlined',
                                          color: 'warning',
                                        },
                                      },
                                      {
                                        component: 'Button',
                                        props: {
                                          variant: 'contained',
                                          color: 'info',
                                          sx: {
                                            borderRadius: '26px',
                                            textTransform: 'none',
                                          },
                                        },
                                        content: 'Vote',
                                      },
                                    ],
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h6',
                                    },
                                    content:
                                      'Proposal Id : 1234567890 by 0x0000...0000',
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'body1',
                                      sx: {
                                        marginX: 1,
                                      },
                                    },
                                    content:
                                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
                                      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                                  },
                                ],
                              },

                              {
                                component: 'Box',
                                props: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginY: 2,
                                  sx: {
                                    width: { xs: '100%', md: '60%' }, // Limiting width to 80% of the parent container
                                    mx: 'auto', // Centering the box
                                  },
                                },
                                children: [
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h4',
                                    },
                                    children: [
                                      {
                                        component: 'Content',
                                        props: {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        },
                                        content:
                                          'Public Notice : 09/11/2021 - 09/11/2021 ',
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Approved',
                                          variant: 'outlined',
                                          color: 'success',
                                        },
                                      },

                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Failed',
                                          variant: 'outlined',
                                          color: 'error',
                                        },
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'On-Going',
                                          variant: 'outlined',
                                          color: 'warning',
                                        },
                                      },
                                      {
                                        component: 'Button',
                                        props: {
                                          variant: 'contained',
                                          color: 'info',
                                          sx: {
                                            borderRadius: '26px',
                                            textTransform: 'none',
                                          },
                                        },
                                        content: 'Vote',
                                      },
                                    ],
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h6',
                                    },
                                    content:
                                      'Proposal Id : 1234567890 by 0x0000...0000',
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'body1',
                                      sx: {
                                        marginX: 1,
                                      },
                                    },
                                    content:
                                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
                                      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                                  },
                                ],
                              },

                              {
                                component: 'Box',
                                props: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginY: 2,
                                  sx: {
                                    width: { xs: '100%', md: '60%' }, // Limiting width to 80% of the parent container
                                    mx: 'auto', // Centering the box
                                  },
                                },
                                children: [
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h4',
                                    },
                                    children: [
                                      {
                                        component: 'Content',
                                        props: {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        },
                                        content:
                                          'Public Notice : 09/11/2021 - 09/11/2021 ',
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Approved',
                                          variant: 'outlined',
                                          color: 'success',
                                        },
                                      },

                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Failed',
                                          variant: 'outlined',
                                          color: 'error',
                                        },
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'On-Going',
                                          variant: 'outlined',
                                          color: 'warning',
                                        },
                                      },
                                      {
                                        component: 'Button',
                                        props: {
                                          variant: 'contained',
                                          color: 'info',
                                          sx: {
                                            borderRadius: '26px',
                                            textTransform: 'none',
                                          },
                                        },
                                        content: 'Vote',
                                      },
                                    ],
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h6',
                                    },
                                    content:
                                      'Proposal Id : 1234567890 by 0x0000...0000',
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'body1',
                                      sx: {
                                        marginX: 1,
                                      },
                                    },
                                    content:
                                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
                                      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                                  },
                                ],
                              },

                              {
                                component: 'Box',
                                props: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginY: 2,
                                  sx: {
                                    width: { xs: '100%', md: '60%' }, // Limiting width to 80% of the parent container
                                    mx: 'auto', // Centering the box
                                  },
                                },
                                children: [
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h4',
                                    },
                                    children: [
                                      {
                                        component: 'Content',
                                        props: {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        },
                                        content:
                                          'Public Notice : 09/11/2021 - 09/11/2021 ',
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Approved',
                                          variant: 'outlined',
                                          color: 'success',
                                        },
                                      },

                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Failed',
                                          variant: 'outlined',
                                          color: 'error',
                                        },
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'On-Going',
                                          variant: 'outlined',
                                          color: 'warning',
                                        },
                                      },
                                      {
                                        component: 'Button',
                                        props: {
                                          variant: 'contained',
                                          color: 'info',
                                          sx: {
                                            borderRadius: '26px',
                                            textTransform: 'none',
                                          },
                                        },
                                        content: 'Vote',
                                      },
                                    ],
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h6',
                                    },
                                    content:
                                      'Proposal Id : 1234567890 by 0x0000...0000',
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'body1',
                                      sx: {
                                        marginX: 1,
                                      },
                                    },
                                    content:
                                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
                                      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                                  },
                                ],
                              },

                              {
                                component: 'Box',
                                props: {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginY: 2,
                                  sx: {
                                    width: { xs: '100%', md: '60%' }, // Limiting width to 80% of the parent container
                                    mx: 'auto', // Centering the box
                                  },
                                },
                                children: [
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h4',
                                    },
                                    children: [
                                      {
                                        component: 'Content',
                                        props: {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        },
                                        content:
                                          'Public Notice : 09/11/2021 - 09/11/2021 ',
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Approved',
                                          variant: 'outlined',
                                          color: 'success',
                                        },
                                      },

                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'Failed',
                                          variant: 'outlined',
                                          color: 'error',
                                        },
                                      },
                                      {
                                        component: 'Chip',
                                        props: {
                                          label: 'On-Going',
                                          variant: 'outlined',
                                          color: 'warning',
                                        },
                                      },
                                      {
                                        component: 'Button',
                                        props: {
                                          variant: 'contained',
                                          color: 'info',
                                          sx: {
                                            borderRadius: '26px',
                                            textTransform: 'none',
                                          },
                                        },
                                        content: 'Vote',
                                      },
                                    ],
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'h6',
                                    },
                                    content:
                                      'Proposal Id : 1234567890 by 0x0000...0000',
                                  },
                                  {
                                    component: 'Typography',
                                    props: {
                                      variant: 'body1',
                                      sx: {
                                        marginX: 1,
                                      },
                                    },
                                    content:
                                      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
                                      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          },
                        },
                        children: [
                          {
                            component: 'Typography',
                            props: {
                              variant: 'h6',
                              inline: true,
                            },
                            content: 'Coming Soon...',
                          },
                          {
                            component: 'Button',
                            props: {
                              variant: 'contained',
                              color: 'primary',
                            },
                            content: 'Create Proposal',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                component: 'Footer',
                props: {},
                children: [
                  {
                    component: 'Container',
                    props: {
                      maxWidth: 'lg',
                    },
                    children: [
                      {
                        component: 'Grid',
                        props: {
                          container: true,
                          sx: {
                            mt: 3,
                          },
                        },
                        children: [
                          {
                            component: 'Grid',
                            props: {
                              item: true,
                              xs: 12,
                              md: 4,
                              container: true,
                            },
                            children: [
                              {
                                component: 'Grid',
                                props: {
                                  item: true,
                                  xs: 3,
                                },
                                children: [
                                  {
                                    component: 'Box',
                                    props: {
                                      src: '',
                                      component: 'img',
                                      sx: {
                                        width: '100%',
                                        maxWidth: '311.77px',
                                        mb: 2,
                                        cursor: 'pointer',
                                      },
                                    },
                                    onClick: () => {
                                      window.open(
                                        'https://winston.services',
                                        '_blank'
                                      );
                                    },
                                    content: null,
                                  },
                                  {
                                    component: 'Grid',
                                    props: {
                                      sx: {
                                        gap: { xs: 1, sm: 2.5 },
                                      },
                                      display: 'flex',
                                      justifyContent: 'start',
                                      ml: 6,
                                      mt: 3.5,
                                    },
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Page',
      path: '/some-page',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/some-page',
          element: { component: 'Page', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'page',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Page Page.',
          },
        ],
      },
    },
    {
      name: 'Link',
      path: '/link',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/link',
          element: { component: 'Page', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'link',
      config: {
        activeSections: [
          {
            component: 'LinkSection',
            props: {},
            content: null,
          },
        ],
      },
    },
    {
      name: 'Multi Step Authentication',
      path: '/mfa',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/mfa',
          element: { component: 'MultiStepAuthentication' },
        },
      ],
      requiredAuth: true,
      key: 'mfa',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Multi Step Authentication Page.',
          },
        ],
      },
    },
    {
      name: 'Forgot Password',
      path: '/forgot-password',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/forgot-password',
          element: { component: 'ForgotPassword', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'forgot-password',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Forgot Password Page.',
          },
        ],
      },
    },
    {
      name: 'Reset Password',
      path: '/reset-password',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/reset-password',
          element: { component: 'ResetPassword', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'reset-password',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Reset Password Page.',
          },
        ],
      },
    },
    {
      name: 'Verify Email',
      path: '/verify-email',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/verify-email',
          element: { component: 'VerifyEmail', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'verify-email',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Verify Email Page.',
          },
        ],
      },
    },
    {
      name: 'Verify Phone Number',
      path: '/verify-phone-number',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/verify-phone-number',
          element: { component: 'VerifyPhoneNumber', requiredAuth: true },
        },
      ],
      requiredAuth: true,
      key: 'verify-phone-number',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Verify Phone Number Page.',
          },
        ],
      },
    },
    {
      name: 'Change Password',
      path: '/change-password',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/change-password',
          element: { component: 'ChangePassword', requiredAuth: true },
        },
      ],
      requiredAuth: true,
      key: 'change-password',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Change Password Page.',
          },
        ],
      },
    },
    {
      name: 'Dashboard',
      path: '/dashboard',
      element: 'DashboardLayout',
      children: [
        {
          index: true,
          path: '/dashboard',
          element: { component: 'Dashboard', requiredAuth: true },
        },
      ],
      requiredAuth: true,
      key: 'dashboard',
      config: {
        activeSections: dashboardTemplate,
      },
    },
    {
      name: 'Not Found',
      path: '*',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '*',
          element: { component: 'NotFound', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'not-found',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            },
            content: 'Not Found Page.',
          },
        ],
      },
    },
  ],
  settings: {
    loading: false,
    language: 'en',
  },
  theme: {
    palette: {
      mode: 'light', // light or dark
    },
    direction: 'ltr', // ltr or rtl
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDrawer: (state, action) => {
      state.drawer = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    toggleModal: (state, action) => {
      state.modal.open = action.payload;
    },
    setModalComponent: (state, action) => {
      state.modal.component = action.payload;
    },
    setModalProps: (state, action) => {
      state.modal.props = action.payload;
    },
    toggleDrawer: (state, action) => {
      state.drawer[action.payload.anchor].open =
        !state.drawer[action.payload.anchor].open;
    },
    setDrawerContent: (state, action) => {
      state.drawer[action.payload.anchor].content = action.payload.content;
    },
    setDrawerAnchor: (state, action) => {
      state.drawer.anchor = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setRoutes: (state, action) => {
      state.routes = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
  },
});

export const {
  setDrawer,
  setErrors,
  setModalComponent,
  setNotifications,
  setRoutes,
  setSettings,
  setTheme,
  toggleModal,
  setModalProps,
  toggleDrawer,
  setDrawerContent,
  setDrawerAnchor,
} = appSlice.actions;

export const appDrawerSelector = (state) => state.app.drawer;
export const appErrorsSelector = (state) => state.app.errors;
export const appModalSelector = (state) => state.app.modal;
export const appNotificationsSelector = (state) => state.app.notifications;
export const appRoutesSelector = (state) => state.app.routes;
export const appSelector = (state) => state.app;
export const appSettingsSelector = (state) => state.app.settings;
export const appThemeSelector = (state) => state.app.theme;

export const selectDrawers = (state) => state.app.drawer;
export const selectDrawer = (anchor) => (state) => state.app.drawer[anchor];
export const selectDrawerContent = (anchor) => (state) =>
  state.app.drawer[anchor].content;
export const selectDrawerOpen = (anchor) => (state) =>
  state.app.drawer[anchor].open;
export const selectDrawerAnchor = (state) => state.app.drawer.anchor;

export const selectModal = (state) => state.app.modal;
export const selectModalComponent = (state) => state.app.modal.component;
export const selectModalProps = (state) => state.app.modal.props;
export const selectModalOpen = (state) => state.app.modal.open;

export const selectRoutes = (state) => state.app.routes;
export const selectRoute = (path) => (state) =>
  state.app.routes.find((route) => route.path === path);
export const selectRouteConfig = (path) => (state) =>
  selectRoute(path)(state).config;

export default appSlice.reducer;
