import React from 'react';
import * as yup from 'yup';
import { Typography } from '@mui/material';
import { useApi } from '../../../hooks/useApi';

const Steps = ({ value }) => {
  const { useLazyGetWhitelabelQuery } = useApi();
  const [getWhitelabel, { data, error, isLoading }] =
    useLazyGetWhitelabelQuery();

  React.useEffect(() => {
    getWhitelabel('winston.services');
  }, [getWhitelabel]);

  if (isLoading) {
    return <Typography variant="body1">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="body1">Error loading data</Typography>;
  }

  switch (value) {
    case 1:
      return (
        <>
          <Typography variant="h4">Link Your Profile</Typography>
          <Typography variant="body1">
            Link your profile to your existing social media accounts.
          </Typography>
        </>
      );
    case 2:
      return (
        <>
          <Typography variant="h4">Register for our newsletter</Typography>
          <Typography variant="body1">
            Join our growing community of blockchain enthusiasts.
          </Typography>
        </>
      );
    case 3:
      return (
        <>
          <Typography variant="h4">Complete your profile</Typography>
          <Typography variant="body1">
            Complete your profile to start using Winston.
          </Typography>
        </>
      );
    default:
      return null;
  }
};
// ToDo: Map form validation schema to config.
const template = [
  {
    component: 'Box',
    props: {
      sx: {
        background: 'linear-gradient(135deg, #2d2169, #2d2169)',
        margin: 0,
        padding: 0,
      },
    },
    children: [
      {
        component: 'Container',
        props: {
          maxWidth: 'xl',
        },
        children: [
          {
            component: 'Box',
            props: {
              sx: {
                paddingTop: '50px',
                display: { xs: 'none', sm: 'block' }, // Hide on xs screens, show on sm and larger
              },
            },
            content: null,
          },
          {
            component: 'Box',
            props: {
              sx: {
                display: 'flex',
                justifyContent: 'center',
                backgroundImage: 'url(/assets/image.png)',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: 'calc(100vh - 100px)',
                width: '100%',
                flex: 1,
                flexDirection: 'column',
              },
            },
            children: [
              {
                component: 'Box',
                props: {
                  sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingY: '75px',
                  },
                },
                children: [
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: `100px`,
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Image',
                    props: {
                      src: './assets/WinstonBrand-White.png',
                      alt: '',
                      style: {
                        maxWidth: '600px',
                        width: '100%',
                      },
                    },
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: `100px`,
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h6',
                          color: 'white',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { md: '36px', xs: '0px' },
                            fontWeight: 'bold',
                            fontSize: { md: '24px', xs: '16px' },
                          },
                        },
                        content:
                          'Your personal assistant to all things blockchain.',
                      },
                    ],
                  },
                ],
              },
            ],
            content: null,
          },
          {
            component: 'Box',
            props: {
              sx: {
                paddingTop: '50px',
                display: { xs: 'none', sm: 'block' }, // Hide on xs screens, show on sm and larger
              },
            },
            content: null,
          },
        ],
      },
    ],
  },
  {
    component: 'Box',
    props: {
      sx: {
        background: 'linear-gradient(135deg, #2d2169, #2d2180)',
        margin: 0,
        padding: 0,
      },
    },
    children: [
      {
        component: 'Container',
        props: {
          maxWidth: 'xl',
        },
        children: [
          {
            component: 'Box',
            props: {
              sx: {
                paddingTop: '150px',
                display: { xs: 'none', sm: 'block' }, // Hide on xs screens, show on sm and larger
              },
            },
            children: [],
          },
          {
            component: 'Box',
            props: {
              sx: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              },
            },
            children: [
              {
                component: 'Box',
                props: {
                  sx: {
                    marginY: `16px`,
                    marginX: `36px`,
                  },
                },
                children: [],
              },
              {
                component: 'Typography',
                props: {
                  variant: 'h4',
                  color: 'white',
                  marginBottom: '16px',
                },
                content: 'Winston Crypto Community',
              },
              {
                component: 'Box',
                props: {
                  sx: {
                    paddingTop: '50px',
                    display: { xs: 'none', sm: 'block' }, // Hide on xs screens, show on sm and larger
                  },
                },
                children: [],
              },
              {
                component: 'Image',
                props: {
                  src: './assets/WinstonCrypto.png',
                  alt: '',
                  width: '280px',
                },
              },
              {
                component: 'Typography',
                props: {
                  variant: 'h5',
                  color: 'white',
                  sx: {
                    marginY: '36px',
                    marginX: '36px',
                  },
                },
                content:
                  'A community built to bring decentralized finance to the common person.',
              },
              {
                component: 'Box',
                props: {
                  sx: {
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '16px',
                  },
                },
                children: [
                  {
                    component: 'Link',
                    props: {
                      href: 'https://discord.gg/rickle-897546129108008960',
                      target: '_blank',
                      rel: 'noopener noreferrer',
                      marginTop: '16px',
                      variant: 'button',
                      sx: {
                        textTransform: 'none',
                        textDecoration: 'none',
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h4',
                        },
                        content: 'Join our Discord',
                      },
                    ],
                  },
                  {
                    component: 'Link',
                    props: {
                      href: 'https://x.com/Rickle_Token@e',
                      target: '_blank',
                      rel: 'noopener noreferrer',
                      marginTop: '16px',
                      variant: 'button',
                      sx: {
                        textTransform: 'none',
                        textDecoration: 'none',
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h4',
                        },
                        content: 'Follow our X',
                      },
                    ],
                  },
                  {
                    component: 'Link',
                    props: {
                      href: 'https://t.me/theickle',
                      target: '_blank',
                      rel: 'noopener noreferrer',
                      marginTop: '16px',
                      variant: 'button',
                      sx: {
                        textTransform: 'none',
                        textDecoration: 'none',
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h4',
                        },
                        content: 'Join our Telegram',
                      },
                    ],
                  },
                ],
              },

              {
                component: 'Box',
                props: {
                  sx: {
                    marginY: `16px`,
                    marginX: `36px`,
                  },
                },
                children: [],
              },
            ],
          },
          {
            component: 'Box',
            props: {
              sx: {
                paddingTop: '150px',
                display: { xs: 'none', sm: 'block' }, // Hide on xs screens, show on sm and larger
              },
            },
            children: [],
          },
          {
            component: 'Box',
            props: {
              sx: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            },
            children: [
              {
                component: 'Box',
                props: {
                  sx: {
                    marginTop: '200px',
                    display: { xs: 'none', sm: 'block' }, // Hide on xs screens, show on sm and larger
                  },
                },
                children: [],
              },
              {
                component: 'List',
                props: {
                  sx: {
                    paddingTop: '150px',
                  },
                },
                children: [
                  {
                    component: 'ListItem',
                    props: {
                      sx: {
                        color: 'white',
                      },
                    },
                    children: [
                      {
                        component: 'Avatar',
                        props: {
                          src: './assets/rickle.png',
                          alt: '',
                          width: '100px',
                          height: '100px',
                          sx: {
                            marginRight: '16px',
                          },
                        },
                      },
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h4',
                        },
                        content: 'Rickle gets us across chains.',
                      },
                    ],
                  },
                  {
                    component: 'ListItem',
                    props: {
                      sx: {
                        color: 'white',
                      },
                    },
                    children: [
                      {
                        component: 'Stack',
                        props: {
                          direction: 'column',
                        },
                        children: [
                          {
                            component: 'Typography',
                            props: {
                              variant: 'body2',
                              paddingLeft: '96px',
                            },
                            content: 'Ethereum : 0x0ff80a1708191c0da8aa600fa487f7ac81d7818c',
                          },
                          {
                            component: 'Typography',
                            props: {
                              variant: 'body2',
                              paddingLeft: '96px',
                            },
                            content: 'Binance : 0xeca15e1bbff172d545dd6325f3bae7b737906737',
                          },
                          {
                            component: 'Typography',
                            props: {
                              variant: 'body2',
                              paddingLeft: '96px',
                            },
                            content: 'Polygon : 0x9fdc23fe295104ac55fef09363c56451d0e37cfa',
                          },
                          {
                            component: 'Typography',
                            props: {
                              variant: 'body2',
                              paddingLeft: '96px',
                            },
                            content: 'Gnosis : 0x2dF5912439d2D14d04a7742346508505288eF367',
                          },
                          {
                            component: 'Typography',
                            props: {
                              variant: 'body2',
                              paddingLeft: '96px',
                            },
                            content: 'Arbitrum : 0x2D0E0Ec9c82C67C4A8d7c7E6c176831f52821B33',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    component: 'ListItem',
                    props: {
                      sx: {
                        color: 'white',
                      },
                    },
                    children: [
                      {
                        component: 'Avatar',
                        props: {
                          src: './assets/winston.png',
                          alt: '',
                          width: '100px',
                          height: '100px',
                          sx: {
                            marginRight: '16px',
                          },
                        },
                      },
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h4',
                        },
                        content: 'Winston connects us with rewards.',
                      },
                    ],
                  },

                  {
                    component: 'ListItem',
                    props: {
                      sx: {
                        color: 'white',
                      },
                    },
                    children: [
                      {
                        component: 'Stack',
                        props: {
                          direction: 'column',
                        },
                        children: [
                          {
                            component: 'Typography',
                            props: {
                              variant: 'body2',
                              paddingLeft: '96px',
                            },
                            content: 'Binance : 0x75578ebBefe274F240B8E1b5859cA34f342157D9',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    component: 'ListItem',
                    props: {
                      sx: {
                        color: 'white',
                      },
                    },
                    children: [
                      {
                        component: 'Avatar',
                        props: {
                          src: './assets/ahwa.png',
                          alt: '',
                          width: '100px',
                          height: '100px',
                          sx: {
                            marginRight: '16px',
                          },
                        },
                      },
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h4',
                        },
                        content: 'Ahwa gives us a voice.',
                      },
                    ],
                  },

                  {
                    component: 'ListItem',
                    props: {
                      sx: {
                        color: 'white',
                      },
                    },
                    children: [
                      {
                        component: 'Stack',
                        props: {
                          direction: 'column',
                        },
                        children: [
                          {
                            component: 'Typography',
                            props: {
                              variant: 'body2',
                              paddingLeft: '96px',
                            },
                            content: 'Binance : 0x3A81caafeeDCF2D743Be893858cDa5AcDBF88c11',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    component: 'ListItem',
                    props: {
                      sx: {
                        color: 'white',
                      },
                    },
                    children: [
                      {
                        component: 'Avatar',
                        props: {
                          src: './assets/wac.png',
                          alt: '',
                          width: '100px',
                          height: '100px',
                          sx: {
                            marginRight: '16px',
                          },
                        },
                      },
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h4',
                        },
                        content: 'Winston Academy helps us learn to earn.',
                      },
                    ],
                  },

                  {
                    component: 'ListItem',
                    props: {
                      sx: {
                        color: 'white',
                      },
                    },
                    children: [
                      {
                        component: 'Stack',
                        props: {
                          direction: 'column',
                        },
                        children: [
                          {
                            component: 'Typography',
                            props: {
                              variant: 'body2',
                              paddingLeft: '96px',
                            },
                            content: 'Binance : 0x181d5cec845973e64bccf9848412948be8a3883e',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                component: 'Box',
                props: {
                  sx: {
                    paddingBottom: '50px',
                    display: { xs: 'none', sm: 'block' }, // Hide on xs screens, show on sm and larger
                  },
                },
                children: [],
              },
            ],
          },
          {
            component: 'Box',
            props: {
              sx: {
                paddingTop: '150px',
                display: { xs: 'none', sm: 'block' }, // Hide on xs screens, show on sm and larger
              },
            },
            children: [],
          },
          {
            component: 'Box',
            props: {
              sx: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            },
            children: [
              {
                component: 'Box',
                props: {
                  sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'start',
                    width: { xs: '80%', md: '66%' },
                  },
                },
                children: [
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingY: '75px',
                        textAlign: 'center',
                      },
                    },
                    children: [
                      {
                        component: 'Image',
                        props: {
                          src: './assets/Winston Handshake.png',
                          alt: '',
                          width: '60%',
                        },
                      },
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            marginY: `100px`,
                          },
                        },
                        children: [
                          {
                            component: 'Button',
                            props: {
                              variant: 'contained',
                              color: 'primary',
                              href: 'https://winston.services/wallet',
                              target: '_blank',
                              rel: 'noopener noreferrer',
                              sx: {
                                textTransform: 'none',
                                textDecoration: 'none',
                              },
                            },
                            content: 'Generate your first Wallet.',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        paddingBottom: { md: '150px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h4',
                      color: 'white',
                    },
                    content: 'Introduction',
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'body1',
                      color: 'white',
                      sx: {
                        marginY: { md: '16px', xs: '0px' },
                        marginX: { md: '36px', xs: '0px' },
                      },
                    },
                    content:
                      'Winston Services is a decentralized platform designed to revolutionize learning to earning opportunities through cryptocurrency, offering a dynamic exosystem for blockchain education and financial independence.',
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: '16px',
                        marginX: { md: '36px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h4',
                      color: 'white',
                    },
                    content: 'Token Intertwining',
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'body1',
                      color: 'white',
                      sx: {
                        marginY: { md: '16px', xs: '0px' },
                      },
                    },
                    content:
                      'WIN, RKL, AHWA, and WAC are interconnected, with each token playing a specific role in the ecosystem. ' +
                      'WIN rewards users, RKL facilitates transactions across chains, WAC incentivizes learning, and AHWA enables governance.',
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: '16px',
                        marginX: { md: '36px', xs: '0px' },
                      },
                    },
                    children: [],
                  },

                  {
                    component: 'Typography',
                    props: {
                      variant: 'h4',
                      color: 'white',
                    },
                    content: 'Multi-Chain Approach',
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          color: 'white',
                          component: 'span',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { md: '36px', xs: '0px' },
                            fontWeight: 'bold',
                          },
                        },
                        content: 'Reasons',
                      },

                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          component: 'span',
                          color: 'white',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { md: '36px', xs: '0px' },
                          },
                        },
                        content:
                          'Improved scalability, reduced transaction fees, and enhanced security.',
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          color: 'white',
                          component: 'span',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { md: '36px', xs: '0px' },
                            fontWeight: 'bold',
                          },
                        },
                        content: 'Impact',
                      },

                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          component: 'span',
                          color: 'white',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { md: '36px', xs: '0px' },
                          },
                        },
                        content:
                          'Being multichain allows Winston to operate across muliple blockchain networks, ensuring efficiency and security.',
                      },
                    ],
                  },

                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingY: '75px',
                        textAlign: 'center',
                      },
                    },
                    children: [
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            paddingY: `56px`,
                          },
                        },
                        children: [],
                      },
                      {
                        component: 'Image',
                        props: {
                          src: './assets/Crypto-Discussion.png',
                          alt: '',
                          width: '60%',
                        },
                      },
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            paddingY: '56px',
                          },
                        },
                        children: [
                          {
                            component: 'Box',
                            props: {
                              display: 'flex',
                              flexDirection: { xs: 'column', md: 'row' },
                              gap: 1,
                            },
                            children: [
                              {
                                component: 'Button',
                                props: {
                                  variant: 'contained',
                                  type: 'button',
                                  fullWidth: true,
                                  sx: { textTransform: 'none' },
                                  href: `https://discord.com/oauth2/authorize?client_id=594415583638847488&response_type=code&redirect_uri=${encodeURIComponent('https://winston.services/link?type=discord')}&scope=identify`,
                                },
                                content:
                                  'Login With Discord and Grab your Roles.',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '56px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h4',
                      color: 'white',
                    },
                    content: 'Project Roadmap and Goals',
                  },

                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          color: 'white',
                          component: 'span',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { md: '36px', xs: '0px' },
                            fontWeight: 'bold',
                          },
                        },
                        content: 'Milestones',
                      },

                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          component: 'span',
                          color: 'white',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { md: '36px', xs: '0px' },
                          },
                        },
                        content:
                          'Achieved milestones include the launch of the platform,  integration with multiple blockchains, and the introduction of new tokens.',
                      },
                    ],
                  },

                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          color: 'white',
                          component: 'span',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { xs: '0px', md: '36px' },
                            fontWeight: 'bold',
                          },
                        },
                        content: 'Future Goals',
                      },
                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          component: 'span',
                          color: 'white',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { xs: '0px', md: '36px' },
                          },
                        },
                        content:
                          'Expand the exosystem, increase user base, and enhance educational incentives.',
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '56px', xs: '0px' },
                      },
                    },
                    children: [],
                  },

                  {
                    component: 'Typography',
                    props: {
                      variant: 'h4',
                      color: 'white',
                    },
                    content: 'Community Participation and Rewards',
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          color: 'white',
                          component: 'span',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { xs: '0px', md: '36px' },
                            fontWeight: 'bold',
                          },
                        },
                        content: 'How to Participate',
                      },

                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          component: 'span',
                          color: 'white',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { xs: '0px', md: '36px' },
                          },
                        },
                        content:
                          'Users can engage through staking, governance, and educational activities.',
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          color: 'white',
                          component: 'span',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { xs: '0px', md: '36px' },
                            fontWeight: 'bold',
                          },
                        },
                        content: 'Rewards',
                      },
                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          component: 'span',
                          color: 'white',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { xs: '0px', md: '36px' },
                          },
                        },
                        content:
                          'Active participation is rewarded with tokens and other incentives, fostering a vibrant and engaged community.',
                      },
                    ],
                  },

                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '56px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingY: '75px',
                      },
                    },
                    children: [
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            marginY: `56px`,
                            backgroundColor: 'white',
                          },
                        },
                        children: [],
                      },
                      {
                        component: 'Image',
                        props: {
                          src: './assets/Winston - Providing Blockchain Services.png',
                          alt: '',
                          style: {
                            maxWidth: '800px',
                            width: '100%',
                          },
                        },
                      },
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            marginY: `56px`,
                          },
                        },
                        children: [
                          {
                            component: 'Button',
                            props: {
                              variant: 'contained',
                              color: 'primary',
                              href: 'https://docs.winston.services',
                              target: '_blank',
                              rel: 'noopener noreferrer',
                              sx: {
                                textTransform: 'none',
                                textDecoration: 'none',
                              },
                            },
                            content: 'Learn More.',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '56px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h4',
                      color: 'white',
                    },
                    content: 'Investment Rationale',
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          color: 'white',
                          component: 'span',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { xs: '0px', md: '36px' },
                            fontWeight: 'bold',
                          },
                        },
                        content: 'Why Invest',
                      },

                      {
                        component: 'Typography',
                        props: {
                          variant: 'body1',
                          component: 'span',
                          color: 'white',
                          sx: {
                            marginY: { md: '16px', xs: '0px' },
                            marginX: { xs: '0px', md: '36px' },
                          },
                        },
                        content:
                          'Strong community support, innovative technology, diverse token use cases, and a clear vision for the furute make Winston an attractive investment opportunity.',
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '56px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h4',
                      color: 'white',
                    },
                    content: 'Conclusion',
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'body1',
                      color: 'white',
                      sx: {
                        marginY: { md: '16px', xs: '0px' },
                        marginX: { xs: '0px', md: '36px' },
                      },
                    },
                    content:
                      'Join the Winston ecosystem to unlock new opportunities, gain valuable knowledge, and be part of a forward-thinking community. Together, we can shape the future of decentralized finance and create a better tomorrow.',
                  },

                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '56px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '56px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '56px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: '16px',
                        marginX: { xs: '0px', md: '36px' },
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                      },
                    },
                    children: [
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            width: { md: '30%', xs: '100%' },
                            height: { md: '100%', xs: 'auto' },
                          },
                        },
                        children: [
                          {
                            component: 'Image',
                            props: {
                              src: './assets/WinstonAndAhwa-Standard.png',
                              alt: '',
                              style: {
                                maxWidth: '100px',
                                width: '100%',
                                height: '100%',
                              },
                            },
                          },
                        ],
                      },
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            width: { md: '70%', xs: '100%' },
                            height: { md: '100%', xs: 'auto' },
                          },
                        },
                        children: [
                          {
                            component: 'Typography',
                            props: {
                              variant: 'h4',
                              color: 'white',
                            },
                            content: 'Meet Winston',
                          },
                          {
                            component: 'Typography',
                            props: {
                              variant: 'body1',
                              color: 'white',
                              sx: {
                                marginY: { md: '16px', xs: '0px' },
                                marginX: { xs: '0px', md: '36px' },
                              },
                            },
                            content:
                              "Winston is your go-to guide for all things related to blockchain. He's here to help you every step of the way as you explore and use blockchain technology, making it easier for you to get the most out of your experience in this new digital financial landscape.",
                          },
                        ],
                      },
                    ],
                  },

                  {
                    component: 'Box',
                    props: {
                      sx: {
                        margin: `36px`,
                      },
                    },
                    children: [],
                  },

                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '16px', xs: '0px' },
                        marginX: { xs: '0px', md: '36px' },
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                      },
                    },
                    children: [
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            width: { md: '70%', xs: '100%' },
                            height: { md: '100%', xs: 'auto' },
                          },
                        },
                        children: [
                          {
                            component: 'Typography',
                            props: {
                              variant: 'h4',
                              color: 'white',
                            },
                            content: 'Meet Ahwa',
                          },
                          {
                            component: 'Typography',
                            props: {
                              variant: 'body1',
                              color: 'white',
                              sx: {
                                marginY: { md: '16px', xs: '0px' },
                                marginX: { xs: '0px', md: '36px' },
                              },
                            },
                            content:
                              "Ahwa is Winston's companion throughout your journey in decentralized finance. Ahwa embodies a lifestyle and serves as the voice of the community, guiding and supporting you as you navigate the complexities of this evolving financial landscape.",
                          },
                        ],
                      },
                      {
                        component: 'Box',
                        props: {
                          sx: {
                            width: '30%',
                            height: '100%',
                          },
                        },
                        children: [
                          {
                            component: 'Image',
                            props: {
                              src: './assets/Ahwa-Large.png',
                              alt: '',
                              style: {
                                maxWidth: '100px',
                                width: '100%',
                                height: '100%',
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },

                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '56px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '56px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: { md: '56px', xs: '0px' },
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h4',
                      color: 'white',
                    },
                    content: 'Meet the Team',
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'body1',
                      color: 'white',
                      sx: {
                        marginY: `16px`,
                        marginX: `36px`,
                      },
                    },
                    content:
                      'Meet the team behind Winston Services, a team of dedicated professionals who are passionate about creating a better future for everyone.',
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: `16px`,
                        marginX: `36px`,
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-evenly',
                        gap: 1,
                      },
                    },
                    children: [
                      {
                        component: 'Card',
                        props: {
                          elevation: 3,
                          sx: {
                            flex: '1 1 30%', // Flex-grow, flex-shrink and flex-basis
                            borderRadius: '16px',
                            display: 'flex', // Ensure the card itself is a flex container
                            flexDirection: 'column',
                          },
                        },
                        children: [
                          {
                            component: 'CardContent',
                            props: {
                              sx: {
                                backgroundColor: 'rgba(45, 33, 105, 0.87)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                height: '100%', // Full height of the card
                              },
                            },
                            children: [
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'h5',
                                  color: 'white',
                                },
                                content: 'Founder',
                              },
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'h5',
                                  color: 'white',
                                },
                                content: 'Michael D.',
                              },
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'h6',
                                  color: 'white',
                                },
                                content: 'AKA: @dreamingrainbow',
                              },
                              {
                                component: 'Box',
                                props: {
                                  sx: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  },
                                },
                                children: [
                                  {
                                    component: 'Avatar',
                                    props: {
                                      src: './assets/WinstonCrypto.png',
                                      alt: '',
                                      sx: {
                                        margin: '36px',
                                        width: '180px',
                                        height: '180px',
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'body1',
                                  color: 'white',
                                  maxWidth: '250px',
                                  width: '100%',
                                  minHeight: '100px',
                                  height: '100%',
                                },
                                content:
                                  'Michael D., a seasoned developer with extensive experience, passionate about innovative and cutting-edge solutions and pushes the boundaries of technology.',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        component: 'Card',
                        props: {
                          elevation: 3,
                          sx: {
                            flex: '1 1 30%', // Flex-grow, flex-shrink and flex-basis
                            borderRadius: '16px',
                            display: 'flex', // Ensure the card itself is a flex container
                            flexDirection: 'column',
                          },
                        },
                        children: [
                          {
                            component: 'CardContent',
                            props: {
                              sx: {
                                backgroundColor: 'rgba(45, 33, 105, 0.87)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                height: '100%', // Full height of the card
                              },
                            },
                            children: [
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'h5',
                                  color: 'white',
                                },
                                content: 'Founder',
                              },
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'h5',
                                  color: 'white',
                                },
                                content: 'Mike E.',
                              },
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'h6',
                                  color: 'white',
                                },
                                content: 'DECEASED',
                              },
                              {
                                component: 'Box',
                                props: {
                                  sx: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  },
                                },
                                children: [
                                  {
                                    component: 'Avatar',
                                    props: {
                                      src: './assets/WinstonCrypto.png',
                                      alt: '',
                                      sx: {
                                        margin: '36px',
                                        width: '180px',
                                        height: '180px',
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'body1',
                                  color: 'white',
                                  maxWidth: '250px',
                                  width: '100%',
                                  minHeight: '100px',
                                  height: '100%',
                                },
                                content:
                                  'Mike E. was a telecommunications expert and a founder of the Rickle Token, he shared his vision for the future of the platform and passed away in 2021.',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        component: 'Card',
                        props: {
                          elevation: 3,
                          sx: {
                            flex: '1 1 30%', // Flex-grow, flex-shrink and flex-basis
                            borderRadius: '16px',
                            display: 'flex', // Ensure the card itself is a flex container
                            flexDirection: 'column',
                          },
                        },
                        children: [
                          {
                            component: 'CardContent',
                            props: {
                              sx: {
                                backgroundColor: 'rgba(45, 33, 105, 0.87)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                height: '100%', // Full height of the card
                              },
                            },
                            children: [
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'h5',
                                  color: 'white',
                                },
                                content: 'Founder',
                              },
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'h5',
                                  color: 'white',
                                },
                                content: 'Brent G.',
                              },
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'h6',
                                  color: 'white',
                                },
                                content: 'AKA: @PaymentsDesigner',
                              },
                              {
                                component: 'Box',
                                props: {
                                  sx: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  },
                                },
                                children: [
                                  {
                                    component: 'Avatar',
                                    props: {
                                      src: './assets/WinstonCrypto.png',
                                      alt: '',
                                      sx: {
                                        margin: '36px',
                                        width: '180px',
                                        height: '180px',
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                component: 'Typography',
                                props: {
                                  variant: 'body1',
                                  color: 'white',
                                  maxWidth: '250px',
                                  width: '100%',
                                  minHeight: '100px',
                                  height: '100%',
                                },
                                content:
                                  'Brent G. is a tenured payments expert and a founder of the Winston Crypto project. His insights to traditional payments and finance are invaluable in the development of the platform.',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginY: `75px`,
                        marginX: `75px`,
                      },
                    },
                    children: [],
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h4',
                      color: 'white',
                    },
                    content: 'M.A.D. Computer Consulting',
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'body1',
                      color: 'white',
                      sx: {
                        marginY: `16px`,
                        marginX: `36px`,
                      },
                    },
                    content:
                      'M.A.D. Computer Consulting is an Arizona registered limited liability company and a team of dedicated professionals who are passionate about creating a better future for everyone.',
                  },
                  {
                    component: 'Typography',
                    props: {
                      variant: 'body1',
                      color: 'white',
                      sx: {
                        marginY: `16px`,
                        marginX: `36px`,
                      },
                    },
                    content:
                      'Our team is dedicated to providing the best possible service in supporting the development of the Winston Crypto project. It is our goal to provide the best possible service to our clients and to the community.',
                  },

                  {
                    component: 'Typography',
                    props: {
                      variant: 'body1',
                      color: 'white',
                      sx: {
                        marginY: `16px`,
                        marginX: `36px`,
                      },
                    },
                    content:
                      'The team has donated their time and resources to the development of the Winston Crypto project. Building the foundation of the platform and shaping the future of it.',
                  },

                  {
                    component: 'Typography',
                    props: {
                      variant: 'caption',
                      color: 'white',
                      sx: {
                        marginY: `16px`,
                        marginX: `36px`,
                      },
                    },
                    content: 'Reference Links:',
                  },
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        marginX: `36px`,
                      },
                    },
                    children: [
                      {
                        component: 'List',
                        props: {
                          disablePadding: true,
                        },
                        children: [
                          {
                            component: 'ListItem',
                            props: {},
                            children: [
                              {
                                component: 'Link',
                                props: {
                                  href: 'https://ecorp.azcc.gov/CommonHelper/GetFilingDocuments?barcode=19031812395887',
                                  target: '_blank',
                                  rel: 'noopener noreferrer',
                                },
                                content:
                                  'Arizona Registered Limited Liability Company',
                              },
                            ],
                          },
                          {
                            component: 'ListItem',
                            props: {},
                            children: [
                              {
                                component: 'Link',
                                props: {
                                  href: 'https://www.linkedin.com/in/mad-cc-llc',
                                  target: '_blank',
                                  rel: 'noopener noreferrer',
                                },
                                content:
                                  'LinkedIn [M.A.D. Computer Consulting]',
                              },
                            ],
                          },
                          {
                            component: 'ListItem',
                            props: {},
                            children: [
                              {
                                component: 'Link',
                                props: {
                                  href: 'https://oracle.winston.services',
                                  target: '_blank',
                                  rel: 'noopener noreferrer',
                                },
                                content: 'Oracle [Winston Services]',
                              },
                            ],
                          },
                          {
                            component: 'ListItem',
                            props: {},
                            children: [
                              {
                                component: 'Link',
                                props: {
                                  href: 'https://docs.winston.services',
                                  target: '_blank',
                                  rel: 'noopener noreferrer',
                                },
                                content: 'Docs [Winston Services]',
                              },
                            ],
                          },
                          {
                            component: 'ListItem',
                            props: {},
                            children: [
                              {
                                component: 'Link',
                                props: {
                                  href: 'https://dev.winston.services',
                                  target: '_blank',
                                  rel: 'noopener noreferrer',
                                },
                                content: 'Winston DApp [Development]',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            component: 'Box',
            props: {
              sx: {
                paddingBottom: '150px',
                display: { xs: 'none', sm: 'block' }, // Hide on xs screens, show on sm and larger
              },
            },
            children: [],
          },
          {
            component: 'Footer',
            props: {},
            children: [
              {
                component: 'Box',
                props: {
                  sx: {
                    display: 'flex',
                    gap: 2,
                  },
                },
                children: [
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: { variant: 'body1', color: 'white' },
                        content:
                          '© 2024 Winston Services. All rights reserved. Inquiries: admin@winston.services',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default template;
